.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 300px;
  }
  .socialMedia {
    display: flex;
    list-style: none;
    li {
      padding: 10px;
      a {
        color: black;
      }
    }
  }
}
