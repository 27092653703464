@import "colors";
@import "mixins";

.hero {
  background-color: $blue1;
  color: $white;
  position: relative;
  height: 70vh;
  @media (max-width: 767px) {
    height: auto;
  }
  .featuredImages {
    position: absolute;
    top: 0;
    bottom: -10vh;
    margin: auto 0;
    display: flex;
    align-items: flex-end;
        @media (max-width: 767px) {
      bottom: 0;
      margin-bottom: -7vh;
      margin-top: -7vh;
    }
    @media(max-width:992px){
      bottom: 0;
      align-items: center;
    }
  }
  .heroContent {
    height: 60vh;
    @media (max-width: 767px) {
      height: auto;
    }
    p {
      @include sectionPadding;
      padding: calc(50vh / 4) 0 !important;
      font-size: 2.75rem;
      line-height: 4rem;

      span {
        font-weight: bold;
      }
    }
  }
}

.featuredImages {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  overflow: hidden;
}

.col {
  overflow: hidden;
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.col {
  animation: slideInFromRight 0.5s ease-out forwards;

  &:nth-child(1) {
    transform: translateX(100%);
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    transform: translateX(200%);
    animation-delay: 1s;
  }

  &:nth-child(3) {
    transform: translateX(300%);
    animation-delay: 1.8s;
  }
}

.roller {
  height: 8.125rem;
  line-height: 4rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 5s infinite 2.2s;
}

@keyframes slide {
  0% {
    top: 0;
  }
  16.5% {
    top: -8.125rem;
  }
  33% {
    top: -8.125rem;
  }
  49.5% {
    top: -16.25rem;
  }
  66% {
    top: -16.25rem;
  }
  82.5% {
    top: 0rem;
  }
  99% {
    top: 0rem;
  }
}
