@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Regular.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Regular.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Regular.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-ExtraLight.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-ExtraLight.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-ExtraLight.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Heavy.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Heavy.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Heavy.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk Book';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Book.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Book.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Book.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Book.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Bold.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Bold.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Bold.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Medium.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Medium.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Medium.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Light.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Light.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Light.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Light.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Thin.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Thin.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Thin.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Thin.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-SemiBold.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-SemiBold.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-SemiBold.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Alte Grotesk';
  src: url('../src/assets/fonts/NeueAlteGrotesk-Black.eot');
  src: url('../src/assets/fonts/NeueAlteGrotesk-Black.eot?#iefix') format('embedded-opentype'),
      url('../src/assets/fonts/NeueAlteGrotesk-Black.woff2') format('woff2'),
      url('../src/assets/fonts/NeueAlteGrotesk-Black.woff') format('woff'),
      url('../src/assets/fonts/NeueAlteGrotesk-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}



body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Neue Alte Grotesk";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


