@import "colors";
@import "mixins";

.services{
    @include sectionPadding;
    background-color: $light-orange;
    h2{
        margin-bottom: 4.375rem;
    }
    .centeredButton{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}