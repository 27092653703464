@import "colors";
@import "mixins";

.contact {
  @include sectionPadding;
  background-color: $purple;
  color: $white;
  label {
    margin-bottom: 1em;
    font-size: 1rem;
  }

  .validation {
    margin-left: 1em;
    display: inline-block;
    font-weight: bold;
    color: $white;
    &.c-orange {
      position: relative;
      z-index: 9;
      &::before {
        background-color: $orange;
        content: "";
        position: absolute;
        width: calc(100% + 4px);
        height: 40%;
        left: -2px;
        bottom: 0;
        z-index: -1;
      }
    }
  }
  .form-control {
    background-color: $light-white;
    border: 0;
    border-radius: 31px;
    padding: 0.575rem 0.75rem;
    margin-bottom: 2em;
    color: $white;
  }
}
