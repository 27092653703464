body,
html {
  max-width: 100%;
}
img {
  width: 100%;
  height: auto;
}

h2{
  margin-bottom: 70px;
  font-weight: bold;

}
