@import "colors";
@import "mixins";

.faq {
  @include sectionPadding;
  position: relative;
  .absoluteImage {
    position: absolute;
    left: 0;
    height: auto;
    width: 25%;
    top: -78px;
  }
  @media (min-width: 767px) {
    .container {
      margin-top: -100px;
    }
  }
}
#accordion {
  border-top: 1px solid;
  font-size: 1rem;
  padding-top: 18px;
  .card-body {
    padding-bottom: 10px;
    margin-bottom: 25px;
    button{
      margin: 50px 0;
    }
  }
  .accordionTitle {
    border-bottom: 1px solid;
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .btn-link {
      text-align: left;
      padding: 0;
      color: $black;
      font-weight: bold;
      text-decoration: none;
      font-size: 1rem;
      padding-right: 20px;
      display: flex;
      align-items: center;
      &[aria-expanded="true"] {
        &:after {content: "-";}
      }
      &[aria-expanded="false"] {
        &:after {content: "+";}
      }
      &:after {
        color: $pink;
        position: absolute;
        right: 0;
        font-size: 1.5rem;
        display: inline-block;
      }
     
    }
    span {
      color: $pink;
      font-weight: bold;
    }
  }
}
