@import "colors";
.no-scroll{
    overflow: hidden;
}
.menu-logo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  img {
    height: 150px;
    width: auto;
    margin-left: -30px;
  }
}
.headerMenuMobile {
  position: relative;
  #links{
    display: none;
  }
  .nav-open{
    display: block !important;
  }
  nav {
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
    z-index: 999999;
    height: calc(100vh - 150px);
    width: 100vw;
    background-color: $orange;
    ul {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: baseline;
      text-align: left;
      list-style: none;
    
      padding-left: 0;
      li {

        font-size: 1.5rem;
        color: white;
        font-weight: bold;
        border-bottom: 1px solid white;
        display: inline-block;
        width: calc(100% - 40px);
        margin:0 auto;
        padding:20px;
      a{
          text-decoration: none;
          color:white;
          &:hover{
            color: white;
          }
        }
      }
    
      padding: 0;
      margin: 0;
      .socialMedia {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 40px);
        margin: 0 auto;
        li{
            text-align: center;
        }
      }
    }
  }
}
