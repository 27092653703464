@import "colors";

$headings: h1, h2, h3, h4, h5;
$colors: (
  green: $green,
  pink: $pink,
  purple: $purple,
  blue1: $blue1,
  blue2: $blue2,
);

@each $heading in $headings {
  @each $color, $value in $colors {
    $class: "c-#{$color}";

    #{$heading}.#{$class}, span.#{$class} {
      position: relative;
      z-index: 1;
      display: inline-block;
      &::before {
        background-color: $value;
        content: "";
        position: absolute;
        width: calc(100% + 4px);
        height: 40%;
        left: -2px;
        bottom: 0;
        z-index: -1;
      }
    }
  }
}