@import "mixins";

.two-col-section{
    position: relative;
    .absoluteImage{
        position: absolute;
        left: 0;
        width: 50%;
        top: calc(75vh / -1.5);;
        padding-right: 50px;
    }
    @media (max-width:767px){
        .absoluteImage{
            position: unset;
            left: 0;
            width: 100vw;
            margin:0;
            padding-right: 0px !important;
        }

    }
    @include sectionPadding;
    h2{
        margin: 0;
    }
    p{
        margin: 2.5rem 0;
        font-size: 1rem;
    }
}