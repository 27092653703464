@import "colors";

@keyframes letterAnim {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translate3d(0, 105%, 0);
  }
  100% {
    transform: translateY(0px);
  }
}

.waveText {
  display: inline-block;
  align-items: center;
  color: white;
  #arrowContainer {
    width: 0px;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    #arrow {
      width: 100%;
      margin-top: -5px;
      margin-left: 5px;
    }
  }

  &:hover {
    color: white;
    #arrowContainer {
      width: 50px;
      animation: arrowAnimation 1s ease-in-out;
    }

    span {
      transform: translateY(0px);
      display: inline-block;
      animation-name: waveAnimation;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;

      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          animation-duration: $i * 0.1s;
        }
      }
    }
  }
}
@keyframes arrowAnimation {
  0% {
    width: 0px;
  }
  50% {
    width: 0px;
  }
  100% {
    width: 50px;
  }
}
@keyframes waveAnimation {
  0% {
    transform: translateY(50px);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(00px);
  }
}

a {
  color: $white;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  button {
  color: $white;
  font-weight: bold;
  display: flex;
  align-items: center;
    padding: 12px 22px;
    border: none;
    background-color: $blue1;
    border-radius: 20px;
    overflow: hidden;
    font-size: 1rem;
  }
}
.whiteTxt {
  color: $white;
}
.blueBtn {
  background-color: $blue1;
}

.purpleBtn {
  background-color: $purple;
}

.pinkBtn {
  background-color: $pink;
}

.greenBtn {
  background-color: $green;
  a {
    color: $black !important;
  }
}
