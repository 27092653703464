.cardItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  h3 {
    font-weight: bold;
  }
  h3,
  p {
    text-align: center;
    font-size: 1rem;
  }
  img {
    height: 3rem;
    margin-bottom: 1.875rem;
  }
}
