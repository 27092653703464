html{
    overflow-x: hidden;
    font-size: 20px;
}
.inView {
  animation: inView 0.7s ease-in-out;
}
h2{
  font-size: 2rem;
}
@keyframes inView {
  0% {
    transform: translateY(100vh);
  }
}

@media (max-width:1440px){
  html{
    font-size: 16px;
}
}
