@import "colors";

.headerMenu {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  img{
    margin-left: -30px;
  }
  nav {
    width: 100vw;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .socialMedia {
        display: flex;
        justify-content: space-around;
        width: 100px;
      }
      li {
        font-size: 1.1rem;
        font-weight: bold;
        a{
          text-decoration: none;
          color:black;
          &:hover{
            color: black;
          }
        }
        &:hover:not(:first-of-type) {
          background-color: $green;
        }
        .logo {
          width: 278px;
          height: auto;
        }
      }
    }
  }
}
@media (max-width:767px){
  .hero .featuredImages {
    position: unset !important; 
    margin: auto 0;
    margin-bottom: -32px;
}
}